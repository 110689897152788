import { Button, Img } from "../../HomeCom";
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

interface Props {
  className?: string;
}

const handleButtonClick = (path: string) => {
  window.location.href = path;
};

const menu = (
  <Menu>
    <Menu.Item key="1" onClick={() => handleButtonClick('/home/subpage1')}>Subpage 1</Menu.Item>
    <Menu.Item key="2" onClick={() => handleButtonClick('/home/subpage2')}>Subpage 2</Menu.Item>
  </Menu>
);

const blogMenu = (
  <Menu>
    <Menu.Item key="1" onClick={() => handleButtonClick('/blog/subpage1')}>Subpage 1</Menu.Item>
    <Menu.Item key="2" onClick={() => handleButtonClick('/blog/subpage2')}>Subpage 2</Menu.Item>
  </Menu>
);

export default function Header({ ...props }: Props) {
  return (
    <header
      {...props}
      className={`${props.className} flex self-stretch justify-between items-start ml-2 gap-5 md:ml-0`}
    >
      <div className="mt-3 flex w-[18%] justify-center gap-3 self-end md:w-full   sm:self-auto">
        <Img
          src="/Images/LogoDark.png"
          alt="Logo Image"
          className=" w-[70%] object-cover  md:w-[20%]"
        />
      </div>
      <div className="flex items-center gap-[30px] lg:flex-col">
        <ul className="!mb-2.5 flex flex-wrap gap-2 self-end md:self-auto">
          <li>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button className=" capitalize text-[85%] bg-transparent border-none ">
                Home <DownOutlined  />
              </Button>
            </Dropdown>
          </li>
          <li>
            <Button
              onClick={() => handleButtonClick('/features')}
              color="gray_900_02"
              shape="round"
              className=" capitalize text-[85%]"
            >
              Features
            </Button>
          </li>
          <li>
            <Button
              onClick={() => handleButtonClick('/integration')}
              color="gray_900_02"
              shape="round"
              className=" capitalize text-[85%]"
            >
              Integration
            </Button>
          </li>
          <li>
            <Dropdown overlay={blogMenu} trigger={['click']}>
              <Button className=" capitalize text-[85%] bg-transparent border-none">
                Blog <DownOutlined />
              </Button>
            </Dropdown>
          </li>
          <li>
            <Button
              onClick={() => handleButtonClick('/contact')}
              color="gray_900_02"
              shape="round"
              className=" capitalize text-[85%] "
            >
              Contact
            </Button>
          </li>
          <Button
          onClick={() => handleButtonClick('/lightauthenticationlogin')}
          color="gray_900_02"
          shape="round"
          leftIcon={
            <div className="flex h-[18px] w-[12px] items-center justify-center">
              <Img
                src="Images/HomePag-img/user.png"
                alt="User"
                className="mb-1 h-[18px] w-[12px] object-cover"
              />
            </div>
          }
          className="min-w-[102px] text-[85%] gap-2 border md:text-[12px] border-solid border-blue_gray-800 font-bold capitalize"
        >
          Login
        </Button>
        </ul>

      </div>
    </header>
  );
}
