import React from "react";
import { Helmet } from "react-helmet";
import { Text, Button, Img, Heading } from "../../../components";
import Header from "../../../components/Header";
import { Link } from "react-router-dom";

export default function VerifyAccount() {

  return (
    <>
      <Helmet>
        <title>Login Authentication - Secure Access to Your Plays</title>
        <meta
          name="description"
          content="Welcome back! Securely log in to find more plays using your email or phone number. Forgot your password? Easily recover access or sign in with your Google account."
        />
      </Helmet>
      <div className="flex w-full min-h-screen flex-col items-center gap-[84px] bg-neutral-neutral_border__day_ md:gap-[63px] sm:gap-[42px]">
        <Header />
        <div className="flex w-full max-w-md min-w-[320px] min-h-[500px] items-center justify-center bg-white-a700 border border-neutral-neutral_border__day_ rounded-[16px] p-[34px] md:w-full sm:p-5">
          <div className="w-full flex flex-col items-center gap-[22px]">
            <div className="mt-1 flex w-full max-w-xs flex-col items-center mt-5 md:w-full">
              <Img src="Images/SignUp-img/Success 1.png" alt="Landing Success" className="h-[64px] w-[64px] mb-8" />
              <Heading size="heading_h5" as="h1" className="!text-neutral-neutral_1100__day_  ">
                  Almost done!
                </Heading>
                <Text as="p" className="text-neutral-neutral_500__day_ mb-8">
                Last step
                </Text>
              <Img src="Images/SignUp-img/mail 1.png" alt="Landing Success" className="h-[69px] w-[69px] mb-3" />
              <div className="flex flex-col items-center self-stretch">
              <Heading size="text_bold" as="h1" className="!text-neutral-neutral_1100__day_  ">
                  Thank you!
                </Heading>
                <Text as="p" className="text-neutral-neutral_500__day_ font-light">
                 We sent an email to
                </Text>
                <Link 
              to="/Gmail" 
               className="text-deep_purple-400 bg-deep-purple-500 font-normal py-1 font-light rounded hover:bg-deep-purple-600 mb-1"
               >
               userEmailAddress
              </Link>
                <Text as="p" className="text-neutral-neutral_500__day_ mb-1 font-light ">
                 Click confirmation link in the email to
                </Text>
                <Text as="p" className="text-neutral-neutral_500__day_ mb-20 font-light ">
                 verify your account 
                </Text>
              </div>
            </div>

            <div className="flex flex-col items-center gap-[18px] self-stretch">
              <Button
                size="sm"
                variant="gradient"
                shape="round"
                color="blue_A200_purple_A200"
                className="self-stretch font-semibold text-white"
              >
                Open Email App & Confirm
              </Button>

              <div className="p-4">
                {/* Aquí puedes agregar más contenido si es necesario */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
