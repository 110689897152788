import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Text, Button, Img, Input, Heading } from "../../components";
import Header from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { SUBMIT_LOGIN_REQUEST } from "components/queries/allQueries";
import md5 from "md5";
import { useAuth } from "components/auth/AuthProvider";

export default function LightAuthenticationLoginPage() {
  const [username, setUsername] = useState<String>("");
  const [password, setPassword] = useState<String>("");
  const {storeTokens} = useAuth();
  const navigate = useNavigate();
  const [sendLoginRequest, { loading, error }] =
    useLazyQuery(SUBMIT_LOGIN_REQUEST);

  const handleLogin = async () => {
    try {
      const response = await sendLoginRequest({
        variables: {
          username: username,
          password: md5(password.toString()),
        },
      });
      console.log(response.data.login)
      if (response && response.data.login) {
        if (response.data.login.pass) {
          storeTokens({
            accessToken: response.data.login.access_token,
            refreshToken: response.data.login.refresh_token,
          });
          navigate("/PaidDashboard"); // Redirige al usuario a la página de pago
        } else {
          console.error("Login failed:", response.data.login.error);
        }
      }
    } catch (err) {
      console.error("Error logging in:", err);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error! {error.message}</p>;

  return (
    <>
      <Helmet>
        s<title>Login Authentication - Secure Access to Your Plays</title>
        <meta
          name="description"
          content="Welcome back! Securely log in to find more plays using your email or phone number. Forgot your password? Easily recover access or sign in with your Google account."
        />
      </Helmet>
      <div className="flex w-full min-h-screen flex-col items-center gap-[84px] bg-neutral-neutral_border__day_ md:gap-[63px] sm:gap-[42px]">
        <Header />
        <div className="flex w-full max-w-md min-w-[320px] min-h-[500px] items-center justify-center bg-white-a700  border border-neutral-neutral_border__day_ rounded-[16px] p-[34px] md:w-full sm:p-5">
          <div className="w-full flex flex-col items-center gap-[22px]">
            <div className="mt-1 flex w-full max-w-xs flex-col items-center gap-2 md:w-full">
              <Img
                src="./Images/Landing Success 1.png"
                alt="Landing Success"
                className="h-[64px] w-[64px]"
              />
              <div className="flex flex-col items-center self-stretch">
                <Heading
                  size="heading_h5"
                  as="h1"
                  className="!text-neutral-neutral_1100__day_  "
                >
                  Welcome Back!
                </Heading>
                <Text
                  as="p"
                  className="text-neutral-neutral_500__day_ font-light"
                ></Text>
                <Text
                  as="p"
                  className="text-neutral-neutral_500__day_ font-light"
                >
                  Let's find some more plays.
                </Text>
              </div>
            </div>
            <div className="flex flex-col items-center gap-[18px] self-stretch">
              <div className="flex flex-col items-start gap-0.5 self-stretch font-extralight ">
                <Text as="p" className="font-light">
                  E-mail/Username or Phone Number
                </Text>
                <Input
                  shape="round"
                  type="email"
                  name="Email Field"
                  placeholder="Email"
                  onChange={(u) => setUsername(u.target.value)}
                  suffix={
                    <Img
                      src="./Images/sms.png"
                      alt="SMS Icon"
                      className="h-[16px] w-[16px]"
                    />
                  }
                  className="gap-4 self-stretch border border-solid border-neutral-neutral_border_day_ "
                />
              </div>
              <div className="flex flex-col items-start gap-1 self-stretch font-extralight">
                <Text as="p" className="font-light">
                  Password
                </Text>
                <Input
                  shape="round"
                  type="password"
                  name="Password Field"
                  placeholder="Password"
                  onChange={(u) => setPassword(u.target.value)}
                  suffix={
                    <Img
                      src="./Images/eye.png"
                      alt="Eye Icon"
                      className="h-[16px] w-[16px]"
                    />
                  }
                  className="gap-4 self-stretch border border-solid border-neutral-neutral_border_day_"
                />
              </div>
              <Button
                size="sm"
                variant="gradient"
                shape="round"
                color="blue_A200_purple_A200"
                className="self-stretch font-semibold text-white-a700 "
                onClick={handleLogin}
              >
                Login
              </Button>
              <a href="/#" className="self-end">
                <Link
                  to="/ResetPass"
                  className="text-neutral-neutral_500__day_ bg-deep-purple-500 font-normal py-1  rounded hover:bg-deep-purple-600"
                >
                  Forgot password?
                </Link>
              </a>
              <div className="flex gap-5 self-stretch">
                <Button
                  shape="round"
                  leftIcon={
                    <Img
                      src="./Images/img_image_157.png"
                      alt="Google Icon"
                      className="h-[20px] w-[20px]"
                    />
                  }
                  className="w-full gap-2.5 border border-solid border-neutral-neutral_border_day_ font-light"
                >
                  Google Account
                </Button>
                <Button
                  shape="round"
                  leftIcon={
                    <Img
                      src="./Images/twitter-x-logo-.png"
                      alt="Twitter Icon"
                      className="h-[30px] w-[30px] mt-2 "
                    />
                  }
                  className="w-full gap-2.5 border border-solid border-neutral-neutral_border_day_ font-light"
                >
                  X Account
                </Button>
              </div>
              <div className="p-4">
                <div className="flex items-center space-x-2">
                  <p className="text-blue-gray-400">
                    <span className="text-neutral-neutral_1100_day_ font-light">
                      Don't have an account?
                    </span>
                  </p>
                  <Link
                    to="/Signup"
                    className="text-deep_purple-400 bg-deep-purple-500  py-1 font-light rounded hover:bg-deep-purple-600"
                  >
                    Sign up
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}