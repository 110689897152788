import { Text } from "./.."; 
import React from "react";

interface Props {
  className?: string;
}

export default function Footer({ ...props }: Props) {
  return (
    <footer {...props} className={`${props.className} flex flex-col gap-20`}>
      <div className="h-px w-full self-stretch bg-neutral-neutral_border_day_" />
      <div className="flex justify-between gap-5 self-stretch sm:flex-col">
        <Text size="description" as="p" className="text-[12px] font-normal text-neutral-neutral_400_day_">
          <span className="text-neutral-neutral_400_day_">© 2024 -&nbsp;</span> 
          <span className="text-brand-brand_1">Up Down Left Right</span>
        </Text>
        <div className="flex w-[18%] justify-center gap-3.5 sm:w-full">
          <div className="flex-1">
            <div className="flex flex-wrap justify-center gap-3.5">
              <a href="./#">
                <Text size="description" as="p" className="text-[12px] font-normal text-neutral-neutral_400_day_">
                  About
                </Text>
              </a>
              <a href="./#">
                <Text size="description" as="p" className="text-[12px] font-normal text-neutral-neutral_400_day_">
                  Careers
                </Text>
              </a>
            </div>
          </div>
          <a href="./#">
            <Text size="description" as="p" className="text-[12px] font-normal text-neutral-neutral_400_day_">
              Policy
            </Text>
          </a>
          <a href="./#">
            <Text size="description" as="p" className="text-[12px] font-normal text-neutral-neutral_400_day_">
              Contact
            </Text>
          </a>
        </div>
      </div>
    </footer>
  );
}
