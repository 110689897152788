import React, { useEffect } from "react";
import { Store } from "react-notifications-component";

interface NotificationProps {
  title: string;
  message: string;
  type: "success" | "danger" | "info" | "warning" | "default";
}

export const NotificationComponent: React.FC<NotificationProps> = ({
  title,
  message,
  type,
}) => {
  useEffect(() => {
    Store.addNotification({
      title,
      message,
      type,
      insert: "top",
      container: "top-right",
      touchSlidingExit: {
        swipe: {
          duration: 400,
          timingFunction: "ease-out",
          delay: 0,
        },
        fade: {
          duration: 400,
          timingFunction: "ease-out",
          delay: 0,
        },
      },
    });
  }, [title, message, type]);

  return null; // Component renders nothing, just triggers the notification
};
