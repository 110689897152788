import { Text, Img, Heading } from "../../../components/Dashboard"; 
import ArbitrageDashboard from "../../../components/Dashboard/ArbitrageDashboard";
import React, { Suspense } from "react";

const arbitrageList = [
  {
    arbitrageDescription: "Arbitrage", 
    activeArbsText: "5 Active Arbs",
    priceText: "$156",
    averageArbsText: "Average arbs per day: 239",
  },
  {
    arbitrageDescription: "Arbitrage", 
    activeArbsText: "5 Active Arbs", 
    priceText: "$156",
    averageArbsText: "Average arbs per day: 239",
  },
  {
    arbitrageDescription: "Arbitrage",
    activeArbsText: "5 Active Arbs",
    priceText: "$156",
    averageArbsText: "Average arbs per day: 239",
  },
  {
    arbitrageDescription: "Referrals Links",
    activeArbsText: "5 Active Arbs", 
    priceText: "$156",
    averageArbsText: "$4900 in Free Bets Available",
  },
];

export default function DashboardSection() {
  return (
    <>
      {/* Dashboard section */} 
      <div className="flex flex-col items-start">
        <Heading
          size="heading_h3"
          as="h1"
          className="text-[28px] font-bold text-neutral_neutral_1100__day_ md:text-[26px] sm:text-[24px]"
        >
          Dashboard
        </Heading>
        <div className="mt-1.5 flex items-center self-stretch">
          <div className="flex items-start gap-0.5">
            <Img src="/Images/Dashboard-img/home.png" alt="Home Icon" className="h-[12px] w-[12px]" />
            <Text
              size="description" 
              as="p"
              className="self-center text-[12px] font-normal text-neutral-neutral_500__day_"
            > 
              Home
            </Text>
          </div>
          <Img src="/Images/Dashboard-img/arrow-right.png" alt="Arrow Icon" className="ml-1.5 h-[12px] w-[12px]" />
          <Text size="description" as="p" className="m-1.5 text-[12px] font-normal text-brand-brand_1">
              Dashboard
          </Text>
        </div>
        <div className="mt-[34px] flex gap-6 self-stretch md:flex-col">
          <Suspense fallback={<div>Loading feed...</div>}>
            {arbitrageList.map((d, index) => (
              <ArbitrageDashboard {...d} key={"cardsList" + index} />
            ))}
          </Suspense>
        </div>
      </div>
    </>
  );
}
