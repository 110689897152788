import React from "react";

type InputProps = Omit<React.ComponentPropsWithoutRef<"input">, "prefix" | "size"> &
Partial<{
  label: string;
  prefix: React.ReactNode;
  suffix: React.ReactNode;
}>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = "",
      name = "",
      placeholder = "",
      type = "text",
      label = "",
      prefix,
      suffix,
      onChange,
      ...restProps
    },
    ref
  ) => {
    return (
      <label className={`${className}`}>
        {!!label && <span>{label}</span>}
        {!!prefix && <span>{prefix}</span>}
        <input
          ref={ref}
          type={type}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          {...restProps}
        />
        {!!suffix && <span>{suffix}</span>}
      </label>
    );
  }
);

export { Input };
