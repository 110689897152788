import { Img, Heading, Input } from "./..";
import React from "react";

interface Props {
  className?: string;
}

export default function Header({ className, ...props }: Props) {
  const [searchBarValue, setSearchBarValue] = React.useState("");

  return (
    <header {...props} className={`self-stretch bg-neutral-neutral_bg__day_ ${className}`}>
      <div className="mt-[18px] flex w-full flex-col items-start gap-3.5">
        <div className="flex w-[58%] items-center gap-[46px] md:w-full md:flex-col">
          <div className="flex flex-1 items-center justify-between gap-5 md:self-stretch sm:flex-col">
            <Img
              src="./Images/LogoDark.png"
              alt="Header Logo"
              className="h-[60px] w-[148px] object-contain" // Ajustado a "w-[148px]"
            />
            <Input
              color="neutral_neutral_100_day" // Corregido para coincidir con los colores definidos
              name="Search Field"
              placeholder="Search"
              value={searchBarValue}
              onChange={(e) => setSearchBarValue(e.target.value)}
              prefix={<Img src="Images/search-normal.png" alt="Contrast" className="h-[18px] w-[20px] cursor-pointer" />}
              suffix={
                searchBarValue.length > 0 ? (
                  <Img
                    src="Images/microphone-2.png"
                    alt="Menu"
                    onClick={() => setSearchBarValue("")}
                    className="h-[18px] w-[20px] cursor-pointer"
                  />
                ) : null
              }
              className="w-[56%] gap-2 rounded-[12px] font-semibold sm:w-full"
            />
          </div>
          <div className="flex w-[18%] items-center gap-2.5 md:w-full">
            <a href="/#">
              <Img src="Images/export.png" alt="External Link" className="h-[16px] w-[16px]" />
            </a>
            <div className="flex flex-1 items-center justify-between gap-5">
              <Heading as="p">Browse</Heading>
              <Img
                src="Images/arrow-down.png"
                alt="Dropdown Arrow"
                className="h-[12px] w-[12px]"
              />
            </div>
          </div>
        </div>
        <div className="h-px w-[84%] self-end bg-neutral-neutral_border_day_" />
      </div>
    </header>
  );
}
