import React from "react";

const sizes = {
  text: "text-[14px] font-normal not-italic", 
  description: "text-[12px] font-normal not-italic",
};

export type TextProps = Partial<{
  className: string;
  as: React.ElementType;
  size: keyof typeof sizes;
}> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  className = "",
  as: Component = "p",
  size = "text",
  ...restProps
}) => {
  return (
    <Component
      className={`text-neutral-neutral_1100_day_ font-poppins ${className} ${sizes[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
