import { gql } from "@apollo/client";

export const SUBMIT_LOGIN_REQUEST = gql`
  query login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      access_token
      refresh_token
      pass
    }
  }
`;

export const SUBMIT_REGISTER_REQUEST = gql`
  query register(
    $username: String!
    $password: String!
    $phone: String!
    $email: String!
    $firstName: String!
    $lastName: String!
  ) {
    register(
      username: $username
      password: $password
      email: $email
      phone: $phone
      firstName: $firstName
      lastName: $lastName
    ) {
      success
      details
    }
  }
`;

export const REFRESH_TOKEN_REQUEST = gql`
  query refresh($refresh_token: String!) {
    refresh(refresh_token: $refresh_token) {
      access_token
      refresh_token
      error
    }
  }
`;

export const FETCH_USER_DATA_REQUEST = gql`
  query fetchUser($access_token: String!) {
    fetchUser(access_token: $access_token) {
      success
      error
      data {
        id
        username
        email
        customerId
        lastLogin
        registerDate
        subscriptions {
          id
          key
          product
          limit
          uses
          left
          expiration
          plan
          price
        }
      }
    }
  }
`;

export const FETCH_ARBITRAGE_REQUEST = gql`
  query fetchArbitrage($access_token: String!, $filter: ArbitrageFilterInput!) {
    fetchArbitrage(filter: $filter, access_token: $access_token) {
      success
      data {
        id
        name
        league
        event
        teamName1
        teamName2
        market
        handicap
        amount
        payout
        live
        middle
        percent
        book1 {
          name
          url
          odds
          bet
        }
        book2 {
          name
          url
          odds
          bet
        }
      }
    }
  }
`;

export const FETCH_POSITIVE_EV_REQUEST = gql`
  query fetchPositiveEv(
    $accessToken: String!
    $filter: PositiveEvFilterInput!
  ) {
    fetchPositiveEv(access_token: $accessToken, filter: $filter) {
      success
      data {
        id
        bettype
        books
        difference
        startTime
        handicap
        league
        market
        name
        book
        started
        odds
        mobile
        web
        states
      }
    }
  }
`;

export const FETCH_OPTIMIZER_REQUEST = gql`
  query FetchOptimizer($accessToken: String!, $filter: OptimizerFilterInput!) {
    fetchOptimizer(access_token: $accessToken, filter: $filter) {
      success
      data {
        away
        bettype
        books
        handicap
        home
        id
        league
        market
        name
        startTime
        started
        states
        tags
      }
    }
  }
`;
