import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Img } from 'components/HomeCom/Img';
import { Heading } from 'components/Heading';

const InfiniteSlider: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div className="slider-container w-full">
      <Heading
        as="h1"
        className="text-center text-cyan-50 uppercase mb-5  md:mb-6 text-[12px] font-poppins font-thin md:text-4xl lg:text-5xl"
      >
        DATA FROM THE BEST
      </Heading>
      <Slider {...settings}>
        {["Coinxy", "Tether", "NEAR Protocol", "Huobi", "Coinxy", "Wrapped Bitcoin", "Solana", "Crypto"].map((item, index) => (
          <div key={index} className="p-2 "> {/* Añade espacio alrededor de cada imagen */}
            <Img src={`./Images/HomePag-img/${item}.png`} alt={`${item} Image`} className="h-8 object-contain border border-green-500 border-x-0 border-y-emerald-700 bg-blue_gray-900_01 w-[120%]  rounded" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default InfiniteSlider;
