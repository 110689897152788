import { Img, Heading, Text } from "../../components/HomeCom";
import UserProfile1 from "../../components/HomeCom/UserProfile1";
import React, { Suspense } from "react";

const reviewList = [
  { userImage: "./Images/HomePag-img/Grp.png", userRatingText: "5.0", userReviewCount: "26 REVIEWS" },
  { userImage: "./Images/HomePag-img/Clipgroup.png", userRatingText: "5.0", userReviewCount: "22 REVIEWS" },
  { userImage: "./Images/HomePag-img/Shape.png", userRatingText: "4.8", userReviewCount: "15 REVIEWS" },
];

export default function TestimonialSection() {
  return (
    <>
      {/* Testimonial section */}
      <div className="relative z-[11] flex flex-col items-center justify-center bg-[url(/public/Images/HomePag-img/Body-2.png)] bg-cover bg-no-repeat py-28 lg:py-20 md:py-16 sm:py-12">
        <div className="container  items-center gap-16 lg:px-5 lg:gap-12 md:gap-10 sm:gap-8">
          {/* Heading Section */}
          <div className="flex flex-col items-center gap-12 sm:gap-2">
            <Heading 
              size="heading3xl"
              as="h2"
              className="text-center !font-manrope capitalize tracking-[-1.80px] text-white text-3xl sm:text-2xl md:text-4xl lg:text-5xl"
            >
              What clients say
            </Heading>
            
            {/* Reviews Section */}
            <div className="w-full flex lg:flex-col items-center gap-8 flex-row md:justify-center">
              <Suspense fallback={<div>Loading feed...</div>}>
                {reviewList.map((d, index) => (
                  <UserProfile1 
                    {...d} 
                    key={`reviewList1-${index}`} 
                    className="w-full md:w-1/3 p-4 md:p-6 bg-blue_gray_900_02 rounded-lg shadow-lg"
                  />
                ))}
              </Suspense>
            </div>
            
            {/* Featured Testimonial */}
            <div className="flex flex-col items-center gap-8 md:flex-row md:items-start md:gap-6 bg-blue_gray-900_01 rounded-lg px-8 py-10 lg:px-1 lg:py-8 md:px-4 md:py-6 sm:px-4 sm:py-4 shadow-md">
              <div className="flex flex-col items-center gap-6 md:items-start md:gap-4">

                <div className="flex items-start  p-10 md:p-6 lg:p-10 mx-40 md:mx-6 lg:mx-1">
                
                <div className="flex flex-col items-center gap-2.5 md:items-start">
                <Img src="./Images/HomePag-img/rec.png" alt="Profile Placeholder" className="h-20 w-20 rounded-full" />
                  <div className="flex items-center  gap-2.5">
                    <div className="h-4 w-8 rounded-sm bg-white-a700_01 " />
                    <Heading size="textmd" as="h3" className="!text-indigo-100 text-sm md:text-base lg:text-lg">
                      Denmark
                    </Heading>
                  </div>
                  <Heading size="headinglg" as="h4" className="mt-2.5 !font-manrope tracking-[-0.66px] text-white text-lg md:text-xl lg:text-2xl">
                    Reynolds Anthony
                  </Heading>
                  <Heading as="h5" className="!font-manrope tracking-[-0.48px] text-indigo-100 text-sm md:text-base lg:text-lg">
                    Cryptocurrency Trader
                  </Heading>
                </div>
                <Img src="./Images/HomePag-img/quotes.png" alt="Quote Image" className="h-8 w-8 md:h-6 md:w-6" />
                  <Text as="p" className="leading-7 text-white text-center md:text-left text-sm md:text-base lg:text-sm p-4 md:p-6 lg:p-1 mx-4 md:mx-6 lg:mx-8">
                    <>
                    "Since I switched to this crypto exchange, my trading <br /> experience has been 
                    nothing short of exceptional. The <br /> platform's intuitive interface combined with
                    lightning-fast <br /> transaction speeds has significantly enhanced my <br /> ability to
                    execute trades effectively."
                    </>
                  </Text>
                  </div>
                  <div className="flex flex-col items-center md:flex-row md:items-center md:justify-start gap-4">
                    <div className="flex items-center gap-6 px-4">
                    <Img
                      src="./Images/HomePag-img/Clut.png" alt="Rating Image"
                      className="h-4 w-15"
                    />
                      <Heading size="headingmd" as="h6" className="font-semibold text-white text-sm md:text-base lg:text-lg">
                        5.0
                      </Heading>
                      <Img
                        src="Images/HomePag-img/Star.png" alt="Rating Signal"
                        className="h-4 w-15 object-contain"
                      />
                  
                  </div>
                  <div className="flex items-center gap-3 lg:mt-20">
                      {[1, 2, 3, 4, 5].map((num) => (
                        <Heading key={num} as="p" className="!text-blue_gray-100_01  text-xs md:text-sm lg:text-base">{num}</Heading>
                      ))}
                    </div>
                    <div className="h-px w-[50%] bg-indigo-900 lg:mt-40" />
                    <div className="h-px w-[10%] bg-teal-a400 lg:mt-40" />
                </div>
              </div>
            </div>
            
            {/* Integration with Crypto Currencies */}
            <div className="flex flex-col items-center gap-16 lg:gap-1 md:gap-8 sm:gap-6">
              <Heading
                size="heading3xl"
                as="h1"
                className="text-center !font-manrope capitalize leading-[72px] tracking-[-1.80px] text-white text-3xl sm:text-2xl md:text-4xl lg:text-4xl"
              >
                Integrated with most popular crypto currencies
              </Heading>
              <div className="w-full flex justify-center">
                <div className="relative flex flex-col items-center">
                  <div className="w-full bg-cover bg-no-repeat p-10 md:p-20 sm:p-4">
                    <Img
                      src="./Images/HomePag-img/Integrated.png" alt="Frame Image"
                      className="w-full h-auto rounded-lg shadow-lg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
