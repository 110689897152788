import React from "react";

const sizes = {
  description: "text-[12px] font-normal not-italic",
  text: "text-[14px] font-normal not-italic",
  textxs: "text-[11px] font-normal not-italic",
};

export type TextProps = Partial<{
  className: string; // Fixed typo from cLassName to className
  as: React.ElementType; // Changed type from any to React.ElementType for better type checking
  size: keyof typeof sizes;
}> & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  className = "",
  as,
  size = "text",
  ...restProps
}) => {
  const Component = as || "p";
  return (
    <Component 
      className={` font-poppins ${className} ${sizes[size]}`} // Fixed template literal syntax
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
