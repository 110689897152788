import { Helmet } from "react-helmet";
import { Text, Button, Img, Input } from "../../components";
import Header from "../../components/Header";
import { Link } from "react-router-dom";

export default function AdminArea() {
  return (
    <>
      <Helmet>
        <title>Reset Password - Secure Access</title>
        <meta
          name="description"
          content="Thank you for requesting a password reset. Please check your email and follow the instructions to reset your password."
        />
      </Helmet>
      <div className="flex w-full min-h-screen flex-col items-center gap-[84px] bg-neutral-neutral_border__day_ md:gap-[63px] sm:gap-[42px]">
        <Header />
        <div className="flex w-full max-w-md min-w-[320px] min-h-[500px] items-center justify-center bg-white-a700 border border-neutral-neutral_border__day_ rounded-[16px] p-[34px] md:w-full sm:p-5">
          <div className="w-full flex flex-col items-center gap-[22px]">
            <div className="mt-1 flex w-full max-w-xs flex-col items-center gap-2 md:w-full">
              <Img src="Images/SignUp-img/Privacy 1.png" alt="Landing Success" className="h-[64px] w-[64px] mb-8" />
              <Text as="h1" className="text-neutral-neutral_100_day_ text-3xl font-extrabold ">
                  Admin-only area
                </Text>
                <Text as="p" className="text-neutral-neutral_500__day_ mb-8 font-light">
               You need to provide a password to <br /> be able to access this page.
              </Text>

            </div>
            <div className="flex flex-col items-start gap-2 self-stretch font-extralight">
                <Text as="p"className="font-light">Password</Text>
                <Input
                  shape="round"
                  type="password"
                  name="Password Field"
                  placeholder="*******"
                  suffix={<Img src="Images/sms.png" alt="SMS Icon" className="h-[16px] w-[16px]" />}
                  className="gap-4 self-stretch border border-solid border-neutral-neutral_border_day_ "
                />
              </div>
            <div className="flex flex-col items-center gap-[18px] self-stretch">
              <Button
                size="sm"
                variant="gradient"
                shape="round"
                color="blue_A200_purple_A200"
                className="self-stretch font-semibold text-white "
              >
                Login
              </Button>
              <Link 
                  to="/ForgotEmail" 
                  className="text-deep_purple-400 bg-deep-purple-500 font-light py-1 rounded hover:bg-deep-purple-600 mb-1"
                >
                  Forgot your password? {/* Replace with dynamic email if needed */}
                </Link>
              <div className="p-4">
                {/* Puedes agregar más contenido aquí si es necesario */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
