import { Img, Heading } from "../../components/HomeCom";
import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState
} from "react-accessible-accordion";

const accordionData = [
  {
    questionNumber: "Q 1",
    questionSeparator: ":",
    questionContent: "How do I get started with trading on your platform?"
  },
  {
    questionNumber: "Q 2",
    questionSeparator: ":",
    questionContent: "What cryptocurrencies can I trade on your exchange?"
  },
  {
    questionNumber: "Q 3",
    questionSeparator: ":",
    questionContent: "Is there a mobile app available for trading?"
  },
  {
    questionNumber: "Q 4",
    questionSeparator: ":",
    questionContent: "What are your trading fees?"
  },
  {
    questionNumber: "Q 5",
    questionSeparator: ":",
    questionContent: "How long do deposits and withdrawals take?"
  }
];

export default function FAOSection() {
  return (
    <>
      {/* FAQ Section */}
      <div className="relative mt-[-144px]  flex flex-col items-center">
        <div className="flex flex-col items-center  justify-center gap-[26px] md:flex-col md:px-5">

          <div className="relative w-full  max-w-[1200px] flex-1 flex flex-col items-center px-4 md:px-0">
            <div className="flex flex-col items-center gap-[38px] lg:px-5">
              <Heading
                size="heading5xl"
                as="h1"
                className="text-center !font-manrope capitalize tracking-[-1.80px] text-white md:text-[40px] sm:text-[34px]"
              >
                Frequently Asked Questions
              </Heading>
              <Accordion preExpanded={[0]} className="w-full  flex flex-col gap-2.5">
                {accordionData.map((d, i) => (
                  <AccordionItem uuid={i} key={`ExpandableList${i}`}>
                    <div className="rounded-[20px] border border-solid border-white-a700_47 bg-blue_gray-900_01 p-3.5">
                      <div className="flex flex-col gap-[18px] rounded-[20px] bg-gray-900_02 p-7 md:p-4">
                        <AccordionItemHeading>
                          <AccordionItemButton className="flex items-center justify-between w-full">
                            <AccordionItemState>
                              {(props) => (
                                <>
                                  <div className="flex items-start gap-5 w-full">
                                    <div className="flex flex-col gap-4 w-full">
                                      <div className="flex items-center gap-2">
                                        <Heading
                                          size="headingxl"
                                          as="h4"
                                          className="!font-manrope tracking-[-1.04px] text-white"
                                        >
                                          {d.questionNumber}
                                        </Heading>
                                        <Heading
                                          size="headingxl"
                                          as="h4"
                                          className="!font-manrope tracking-[-1.04px] text-white"
                                        >
                                          {d.questionSeparator}
                                        </Heading>
                                      </div>
                                      <Heading
                                        size="headingxl"
                                        as="h4"
                                        className="!font-manrope tracking-[-1.04px] text-white"
                                      >
                                        {d.questionContent}
                                      </Heading>
                                    </div>
                                    {props?.expanded ? (
                                      <Img
                                        src="/Images/HomePag-img/Polygon 2.png"
                                        alt="Favorite Icon"
                                        className="h-[18px] w-[18px]"
                                      />
                                    ) : (
                                      <Img
                                        src="/Images/HomePag-img/Polygon 1.png"
                                        alt="Toggle Icon"
                                        className="h-[18px] w-[18px]"
                                      />
                                    )}
                                  </div>
                                </>
                              )}
                            </AccordionItemState>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="flex flex-col gap-5 md:mx-0">
                            <Heading size="textlg" as="p" className="!text-indigo-100">
                              Here are some points about the cryptocurrencies you can trade on our exchange:
                            </Heading>
                            <div className="flex flex-col gap-[30px]">
                              <Heading size="textlg" as="p" className="leading-8 !text-indigo-100">
                                <>
                                  Major Cryptocurrencies.
                                  <br />
                                  New and Emerging Tokens.
                                  <br />
                                  Popular Altcoins.
                                </>
                              </Heading>
                              <Heading size="textlg" as="p" className="leading-8 !text-indigo-100">
                                We offer a wide range of cryptocurrencies for trading, including popular options like Bitcoin (BTC), Ethereum (ETH), Ripple (XRP), and many others. Our platform regularly updates the list of available cryptocurrencies access options.
                              </Heading>
                            </div>
                          </div>
                        </AccordionItemPanel>
                      </div>
                    </div>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
            {/* <Img
              src="Images/HomePag-img/Eliments.png"
              alt="Decorative Elements"
              className="absolute bottom-0 left-0 right-0 top-0 h-[488px] w-[96%] object-contain"
            /> */}
            
          </div>
        </div>

      </div>
      <div className="flex justify-end  h-[350px] ">
  <img
    src="./Images/HomePag-img/Clip.png"
    alt="Eth Coin Icon"
    className="h-[146px] w-[4%] object-contain md:w-[10%] mt-80 "
  />
</div>
      <Img
            src="./Images/HomePag-img/btc-2.png"
            alt="Btctwo"
            className="mb-2.5 h-[100px] w-[4%] object-contain md:w-[10%] md:h-auto"
          />

    </>
  );
}
