import React from "react";

const shapes = {
  round: "rounded-[10px]",
} as const;

const variants = {
  fill: {
    blue_gray_900_01: "bg-blue_gray-900_01 text-white-a700_01",
  },
} as const;

const sizes = {
  xs: "h-[60px] pl-[22px] pr-3 text-[16px]",
} as const;

type InputProps = Omit<React.ComponentPropsWithoutRef<"input">, "prefix" | "size"> &
  Partial<{
    label: string;
    prefix: React.ReactNode;
    suffix: React.ReactNode;
    shape: keyof typeof shapes;
    variant: keyof typeof variants;
    size: keyof typeof sizes;
    color: keyof typeof variants.fill;
  }>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = "",
      name = "",
      placeholder = "",
      type = "text",
      label = "",
      prefix,
      suffix,
      onChange,
      shape,
      variant = "fill",
      size = "xs",
      color = "blue_gray_900_01",
      ...restProps
    },
    ref
  ) => {
    const shapeClass = shape ? shapes[shape] : "";
    const variantClass = variant && color ? variants[variant][color] : "";
    const sizeClass = size ? sizes[size] : "";

    return (
      <label
        className={`flex items-center justify-center gap-2 cursor-text text-[16px] border-white-a700_14 border border-solid bg-deep_purple-a400_2d  ${shapeClass} ${variantClass} ${sizeClass} ${className}`}
      >
        {!!label && <span>{label}</span>}
        {!!prefix && prefix}
        <input
          ref={ref}
          type={type}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          {...restProps}
        />
        {!!suffix && suffix}
      </label>
    );
  }
);

export { Input };
