import React from "react";

// Define los tamaños disponibles para el componente Text.
const sizes = {
  text3xl: "text-[26px] font-normal not-italic lg:text-[22px] md:text-[24px] sm:text-[22px]",
} as const;

// Define las propiedades del componente Text.
export type TextProps = Partial<{
  className: string;
  as: React.ElementType; // Usa React.ElementType para permitir cualquier tipo de elemento.
  size: keyof typeof sizes; // Asegúrate de que el tamaño sea uno de los definidos en sizes.
}> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

// Componente funcional Text.
const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  className = "",
  as: Component = "span", // Usa 'span' como valor predeterminado para 'as'.
  size = "text3xl",
  ...restProps
}) => {
  return (
    <Component
      className={`text-indigo-100 font-outfit ${className} ${sizes[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
