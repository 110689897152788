import React from "react";

// Define el objeto de tamaños para los estilos de texto y encabezado.
const sizes = {
  texts: "text-[12px] font-medium",
  textmd: "text-[18px] font-medium lg:text-[15px]",
  textlg: "text-[20px] font-medium lg:text-[12px]",
  textxl: "text-[22px] font-medium lg:text-[14px]",
  text2xl: "text-[24px] font-medium lg:text-[14px] md:text-[22px]",
  headingxs: "text-[14px] font-bold",
  headings:"text-[16px] font-bold lg:text-[10px]",
  headingmd: "text-[18px] font-bold lg:text-[11px]",
  headinglg: "text-[22px] font-bold lg:text-[14px]",
  headingxl: "text-[26px] font-bold lg:text-[16px] md:text-[24px] sm:text-[22px]",
  heading2xl: "text-[30px] font-bold lg:text-[18px] md:text-[28px] sm:text-[26px]",
  heading3xl: "text-[32px] font-semibold lg:text-[20px] md:text-[30px] sm:text-[28px]",
  heading4xl: "text-[36px] font-bold lg:text-[21px] md:text-[34px] sm:text-[32px]",
  heading5xl: "text-[50px] font-bold lg:text-[30px] md:text-[52px] sm:text-[46px]",
  heading6xl: "text-[90px] font-bold lg:text-[35px] md:text-[48px]",
} as const; // Usar `as const` para que TypeScript trate las claves como literales de cadena

// Define los tipos para los props del componente Heading.
export type HeadingProps = Partial<{
  className: string;
  as: any; // Aceptar solo elementos HTML
  size: keyof typeof sizes; // Aceptar solo las claves de `sizes`
}> & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>,HTMLSpanElement>; // Aceptar props de HTMLSpanElement

// Componente funcional Heading.
const Heading: React.FC<React.PropsWithChildren<HeadingProps>> = ({
  children,
  className = "",
  size = "texts",
  as,
  ...restProps
}) => {
    const Component =as ||"h6";

  return (
    <Component
      className={`text-white-a700_01 font-manrope ${className} ${sizes[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Heading };
