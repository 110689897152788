import React from "react";

// Define shapes with their styles
const shapes = {
  round: "rounded-lg",
  square: "rounded-[24px]",
  circle: "rounded-[50%]"
} as const;

// Define variants and their possible colors
const variants = {
  gradient: {
    blue_A200_purple_A200: "bg-gradient text-neutral-neutral_bg___day_ ",
  },
  fill: {
    "neutral_neutral_bg_[day]": "bg-neutral-neutral_bg___day_ text-neutral-neutral__1100_day",
  },
} as const;

// Define sizes with their styles
const sizes = {
  lg: "h-[52px] px-[34px] text-[12px]",
  sm: "h-[52px] pl-3 pr-[30px] text-[16px]",
  "3xl": "h-[70px] px-[34px] text-[18px]",
  "2xl": "h-[66px] px-3",
  xs: "h- [50px] pl-3 pr-[ 130px] text-[ 12px]",
  md: "h-[50px] px-5 text-[18px]",
  xl: "h-[56px] px-4",
  "4xl": "h-[72px]",
} as const;

// Extract keys for variants and colors
type Variant = keyof typeof variants;
type Color<V extends Variant> = keyof typeof variants[V];

// Define ButtonProps with specific color types
type ButtonProps = Omit<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  "onClick"
> & {
  className?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  onClick?: () => void;
  shape?: keyof typeof shapes;
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  color?: string; // Use a string type and handle validation in runtime
};

// Define the Button component
const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape = "round",
  variant = "fill",
  size = "xs",
  color = "neutral_neutral_bg_[day]",
  ...restProps
}) => {
  // Determine the appropriate class names
  const shapeClass = shapes[shape];
  const sizeClass = sizes[size];

  // Handle color in a type-safe manner
  const variantClass = variant in variants
    ? (color in variants[variant] ? variants[variant][color as Color<typeof variant>] : "")
    : "";

  return (
    <button
      className={`flex flex-row items-center justify-center text-center cursor-pointer whitespace-nowrap rounded-lg ${shapeClass} ${sizeClass} ${variantClass} ${className}`}
      {...restProps}
    >
      {leftIcon && leftIcon}
      {children}
      {rightIcon && rightIcon}
    </button>
  );
};

export { Button };
