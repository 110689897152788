import React from "react";

// Define shapes with their styles
const shapes = {
  round: "rounded-lg", // Adjust the radius as needed
} as const;

// Define variants with their possible colors
const variants = {
  fill: {
    neutral_neutral_100_day: "bg-neutral-neutral_100__day_",
    neutral_neutral_bg_day: "bg-neutral-neutral_bgday_ text-neutral-neutral_300_day_",
    white: "bg-white",
  },
} as const;

// Define sizes with their styles
const sizes = {
  xs: "h-[50px] px-3 text-[12px]",  // Extra small
  sm: "h-[52px] px-3.5 text-[14px]", // Small
  md: "h-[60px] px-4 text-[16px]", // Medium
  lg: "h-[72px] px-5 text-[18px]", // Large
} as const;

// Define border colors
const borderColors = {
  gray: "border border-gray-300",
  // Add other border colors if needed
} as const;

// Type for variant colors
type VariantColors = keyof typeof variants.fill;
type BorderColors = keyof typeof borderColors;

// Define the type for InputProps
type InputProps = Omit<React.ComponentPropsWithoutRef<"input">, "prefix" | "size"> & {
  label?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  shape?: keyof typeof shapes;
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  color?: VariantColors;
  borderColor?: BorderColors; // Added border color prop
};

// Define the Input component
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = "",
      name = "",
      placeholder = "",
      type = "text",
      label = "",
      prefix,
      suffix,
      onChange,
      shape = "round", // Default shape
      variant = "fill", // Default variant
      size = "sm", // Default size
      color = "white", // Default color
      borderColor = "gray", // Default border color
      ...restProps
    },
    ref
  ) => {
    // Determine the appropriate class names
    const shapeClass = shape ? shapes[shape] : "";
    const sizeClass = size ? sizes[size] : "";
    const variantClass = variant && color && variants[variant] ? variants[variant][color] : "";
    const borderClass = borderColor ? borderColors[borderColor] : "";

    return (
      <label
        className={`flex items-center cursor-text text-neutral-neutral_300_day text-[14px] ${shapeClass} ${sizeClass} ${variantClass} ${borderClass} ${className}`}
      >
        {label && <span className="mr-2">{label}</span>}
        {prefix && <span className="mr-2">{prefix}</span>}
        <input
          ref={ref}
          type={type}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          {...restProps}
        />
        {suffix && <span className="ml-2">{suffix}</span>}
      </label>
    );
  }
);

export { Input };
