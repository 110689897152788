import { Img, Heading } from "../../HomeCom";
import React from "react";

interface Props {
  className?: string;
  headingText?: React.ReactNode;
  descriptionText?: React.ReactNode;
  Imagesrc?: string;
}

export default function DecentralizedIdentityVerification({
  headingText = "Decentralized Identity Verification",
  descriptionText = "Decentralized Identity Verification offers a secure and transparent solution for authenticating identities without relying.",
  Imagesrc,
  ...props
}: Props) {
  return (
    <div
      {...props}
      className={`flex sm:flex-row items-center rounded-[20px] ${props.className}`}
    >
      <div className="flex flex-col items-start justify-center  sm:p-8 w-full sm:w-1/2">
        <Heading
          size="heading2xl"
          as="h3"
          className="!font-manrope capitalize leading-10 tracking-[-0.60px] text-center sm:text-left text-[20px] sm:text-[25px]"
        >
          {headingText}
        </Heading>
        <Heading
          size="textmd"
          as="p"
          className="w-full leading-7 !text-indigo-100 text-center sm:text-left mt-4 text-[14px] sm:text-[15px]"
        >
          {descriptionText}
        </Heading>
      </div>
      <div className="w-full sm:w-1/2 mt-6 sm:mt-1 flex justify-center items-center">
        <Img
          src={Imagesrc}
          alt="Feature Image"
          className="h-auto w-50  object-contain"
        />
      </div>
    </div>
  );
}
