import React from "react";

// Define sizes with their styles
const sizes = {
  heading_h5: "tracking-[0.11px] text-[24px] font-bold md:text-[22px]",
  text_bold: "text-[14px] font-semibold",
  texts: "text-[12px] font-medium",
  textmd: "text-[18px] font-medium lg:text-[15px]",
  textlg: "text-[20px] font-medium lg:text-[17px]",
  textxl: "text-[22px] font-medium lg:text-[18px]",
  text2xl: "text-[24px] font-medium lg:text-[20px] md:text-[22px]",
  headingxs: "text-[14px] font-bold",
  headings: "text-[16px] font-bold lg:text-[13px]",
  headingmd: "text-[18px] font-bold lg:text-[15px]",
  headinglg: "text-[22px] font-bold lg:text-[18px]",
  headingxl: "text-[26px] font-bold lg:text-[22px] md:text-[24px] sm:text-[22px]",
  heading2xl: "text-[30px] font-bold lg:text-[25px] md:text-[28px] sm:text-[26px]",
  heading3xl: "text-[32px] font-semibold lg:text-[27px] md:text-[30px] sm:text-[28px]",
  heading4xl: "text-[36px] font-bold lg:text-[30px] md:text-[34px] sm:text-[32px]",
  heading5xl: "text-[60px] font-bold lg:text-[51px] md:text-[52px] sm:text-[46px]",
  heading6xl: "text-[90px] font-bold lg:text-[90px] md:text-[48px]",
};

// Define HeadingProps including className, as, size, and other props
export type HeadingProps = Partial<{
  className: string;
  as: React.ElementType; // Updated type for 'as' to be more specific
  size: keyof typeof sizes;
}> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

// Define the Heading component
const Heading: React.FC<React.PropsWithChildren<HeadingProps>> = ({
  children,
  className = "",
  size = "text_bold",
  as: Component = "h6", // Default to "h6" if 'as' is not provided
  ...restProps
}) => {
  return (
    <Component
      className={`text-neutral-neutral_500__day_ font-poppins ${className} ${sizes[size]}`} // Use backticks for template strings
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Heading };
