/* eslint-disable react-hooks/exhaustive-deps */
import "./Buttons.css";
import { Helmet } from "react-helmet";
import { Text, Img, Input } from "../../../components/Dashboard";
import { ReactTable } from "../../../components/Dashboard/ReactTable";

import { createColumnHelper } from "@tanstack/react-table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Sidebar1 from "components/Dashboard/Sidebar1";
import { useLazyQuery } from "@apollo/client";
import {
  FETCH_POSITIVE_EV_REQUEST,
} from "components/queries/allQueries";
import { useAuth } from "components/auth/AuthProvider";

import { Select } from "antd";
import Header from "components/Dashboard/Header";
import { CloseCircle } from "iconsax-react";

const { Option } = Select;

const AntSelectBox = ({ ...props }) => {
  return (
    <Select mode="multiple" maxTagCount={2} {...props} style={{ width: "25%" }}>
      {leagueOptions.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

// Uso d

const leagueOptions = [
  { label: "WNBA", value: "wnba" },
  { label: "NBA", value: "nba" },
  { label: "NFL", value: "nfl" },
  { label: "MLB", value: "mlb" },
];

const marketOptions: options[] = [
  { label: "Over/Under", value: "o/u" },
  { label: "Spread", value: "sp" },
  { label: "Moneyline", value: "ml" },
  { label: "Unknown", value: "uk" },
];

type options = {
  label: string;
  value: string;
};

type Table3RowType = {
  idLabel: string;
  nameLabel: string;
  leagueLabel: string;
  betTypeLabel: string;
  booksLabel: any | string;
  handicapLabel: string;
  marketLabel: string;
  startTimeLabel: string;
  liveLabel: boolean | string;
  differenceLabel: string;
  oddsLabel: string;
  mobileLabel: string;
  webLabel: string;
  bookLabel: string;
  statesLabel: any | string;
};

type booksLabel = {
  book1: book;
  book2: book;
};

type book = {
  name: string;
  odds: string;
  link: string;
};

type PositiveEvData = {
  id: string;
  name: string;
  league: string;
  bettype: string;
  books: any;
  handicap: string;
  market: string;
  startTime: string;
  started: boolean;
  difference: string;
  odds: string;
  mobile: string;
  web: string;
  book: string;
  states: any;
};

function arraysMatchUnordered(arr1: any[], arr2: any[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
}

function convertMarketOption(opt: string): string {
  switch (opt) {
    case "OVER":
      return "O/U";
    case "UNDER":
      return "O/U";
    case "MONEYLINE":
      return "ML";
    case "SPREAD":
      return "SP";
    default:
      return "UK";
  }
}

export default function PositiveEvPage() {
  const [keywordOption, setKeywordOption] = useState("");
  const { tokens } = useAuth();
  const [positiveEv, setPositiveEv] = useState<Table3RowType[]>([]);
  const [filteredPositiveEv, setFilteredPositiveEv] = useState<Table3RowType[]>(
    [],
  );
  const [leagueOption, setLeagueOption] = useState<options[]>([]);
  const [marketOption, setMarketOption] = useState<options[]>([]);
  const [bookOption] = useState<options[]>([]);
  const [fetchOptimizer] = useLazyQuery(FETCH_POSITIVE_EV_REQUEST);
  const [isLive] = useState(true);

  const handleFetchPositiveEv = useCallback(async () => {
    try {
      const response = await fetchOptimizer({
        variables: {
          accessToken: tokens.accessToken,
          filter: {
            sports: leagueOptions.map((option) => option.value),
            markets: marketOption.map((option) => option.value),
          },
        },
      });
      if (response.error) {
        console.error("Fetching optimizer failed:", response.error);
      } else if (response.data.fetchPositiveEv.success) {
        const newOdds: Table3RowType[] = [];
        for (const ev of response.data.fetchPositiveEv
          .data as PositiveEvData[]) {
          newOdds.push({
            idLabel: ev.id,
            nameLabel: ev.name,
            leagueLabel: ev.league,
            betTypeLabel: convertMarketOption(ev.bettype),
            booksLabel: ev.books,
            handicapLabel: ev.handicap,
            marketLabel: ev.market,
            startTimeLabel: ev.startTime,
            liveLabel: ev.started,
            differenceLabel: ev.difference,
            oddsLabel: ev.odds,
            mobileLabel: ev.mobile,
            webLabel: ev.web,
            bookLabel: ev.book,
            statesLabel: ev.states,
          });
        }
        if (!arraysMatchUnordered(newOdds, positiveEv)) {
          setPositiveEv(newOdds);
        }

        console.log("Fetching optimizer successful", response.data.login);
      } else {
        console.error("Fetching optimizer failed:", response.data.login.error);
      }
    } catch (err) {
      console.error("Error fetching optimizer:", err);
    }
  }, [positiveEv, fetchOptimizer, marketOption, tokens.accessToken]);

  useEffect(() => {
    let kw = keywordOption.trim().toLowerCase();
    let filteredPositiveEv: Table3RowType[] = positiveEv;
    if (!isLive) {
      filteredPositiveEv = filteredPositiveEv.filter((arb) => !arb.liveLabel);
    }

    if (bookOption && bookOption.length > 1) {
      filteredPositiveEv = filteredPositiveEv.filter(
        (arb) =>
          bookOption.some(
            (book) =>
              (arb.booksLabel as booksLabel).book1.name.toLowerCase() ===
              String(book),
          ) &&
          bookOption.some(
            (book) =>
              (arb.booksLabel as booksLabel).book2.name.toLowerCase() ===
              String(book),
          ),
      );
    }

    if (marketOption && marketOption.length > 0) {
      filteredPositiveEv = filteredPositiveEv.filter((arb) =>
        marketOption.some(
          (option) => arb.betTypeLabel.toLowerCase() === String(option),
        ),
      );
    }

    if (leagueOption && leagueOption.length > 0) {
      filteredPositiveEv = filteredPositiveEv.filter((arb) =>
        leagueOption.some(
          (option) => arb.leagueLabel.toLowerCase() === String(option),
        ),
      );
    }

    if (kw && kw !== "") {
      filteredPositiveEv = filteredPositiveEv.filter(
        (arb) =>
          arb.nameLabel.toLowerCase().includes(kw) ||
          arb.leagueLabel.toLowerCase().includes(kw) ||
          arb.marketLabel.toLowerCase().includes(kw),
      );
    }
    setFilteredPositiveEv(filteredPositiveEv);
  }, [
    isLive,
    positiveEv,
    setFilteredPositiveEv,
    bookOption,
    marketOption,
    leagueOption,
    keywordOption,
  ]);

  useEffect(() => {
    handleFetchPositiveEv();
    const fa = setInterval(() => {
      handleFetchPositiveEv();
    }, 15 * 1000); 
    return () => clearInterval(fa);
  },[]);

  useEffect(() => {
    console.log("leagueOption changed", leagueOption);
  }, [leagueOption]);

  useEffect(() => {
    console.log("marketOption changed", marketOption);
  }, [marketOption]);

  const table3Columns = useMemo(() => {
    const table3ColumnHelper = createColumnHelper<Table3RowType>();
    return [
      table3ColumnHelper.accessor("leagueLabel", {
        cell: (info) => (
          <div className="flex items-center gap-2">
            {info.getValue<any>() === "WNBA" && (
              <img
                alt=""
                src="./Images/BooksIcon/wnba.jpeg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<any>() === "NBA" && (
              <img
                alt=""
                src="./Images/BooksIcon/nba.svg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<any>() === "NFL" && (
              <img
                alt=""
                src="./Images/BooksIcon/nfl.jpeg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<any>() === "MLB" && (
              <img
                alt=""
                src="./Images/BooksIcon/mlb.jpeg"
                style={{ width: "15%", height: "15%" }}
              />
            )}

            <Text
              as="p"
              className="text-[14px] font-normal text-neutral-neutral_1100__day_ "
            >
              {info.getValue<any>()}
            </Text>
          </div>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            League
          </Text>
        ),
        meta: { width: "5%" },
      }),
      table3ColumnHelper.accessor("betTypeLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Bet Type
          </Text>
        ),
        meta: { width: "8%" },
      }),
      table3ColumnHelper.accessor("handicapLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Handicap
          </Text>
        ),
        meta: { width: "8%" },
      }),
      table3ColumnHelper.accessor("marketLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Market
          </Text>
        ),
        meta: { width: "8%" },
      }),
      table3ColumnHelper.accessor("nameLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Name
          </Text>
        ),
        meta: { width: "12%" },
      }),
      table3ColumnHelper.accessor("bookLabel", {
        cell: (info) => (
          <div className="flex items-center gap-4">
             {info.getValue<string>() === "fliff" && (
              <img
              alt=""
              src="./Images/BooksIcon/getfliff.jpeg"
                style={{ width: "15%", height: "15%" }}
              />
            )}
            {info.getValue<string>() === "espnbet" && (
              <img
              alt=""
              src="./Images/BooksIcon/espnbet.jpeg"
                style={{ width: "15%", height: "15%" }}
              />
            )}
            {info.getValue<string>() === "fanatics" && (
              <img
              alt=""
              src="./Images/BooksIcon/fanatics.webp"
                style={{ width: "15%", height: "15%" }}
              />
            )}
              {info.getValue<string>() === "caesars" && (
              <img
              alt=""
              src="./Images/BooksIcon/caesars.webp"
                style={{ width: "15%", height: "15%" }}
              />
            )}
            {info.getValue<string>() === "betrivers" && (
              <img
              alt=""
              src="./Images/BooksIcon/BetRivers.jpeg"
                style={{ width: "15%", height: "15%" }}
              />
            )}
            {info.getValue<string>() === "betmgm" && (
              <img
              alt=""
              src="./Images/BooksIcon/BetMGM.png"
                style={{ width: "15%", height: "15%" }}
              />
            )}
            {info.getValue<string>() === "fanduel" && (
              <img
              alt=""
              src="./Images/BooksIcon/fanduel.png"
                style={{ width: "15%", height: "15%" }}
              />
            )}

            {info.getValue<string>() === "draftkings" && (
              <img
              alt=""
              src="./Images/BooksIcon/draftkings.png"
                style={{ width: "15%", height: "15%" }}
              />
            )}

            {info.getValue<string>() === "pinnacle" && (
              <img
              alt=""
              src="./Images/BooksIcon/Pinnacle.jpeg"
                style={{ width: "15%", height: "15%" }}
              />
            )}
            {info.getValue<string>() === "hardrock" && (
              <img
              alt="" 
                src="./Images/BooksIcon/hardrock.webp"
                style={{ width: "15%", height: "15%" }}
              />
            )}
             {info.getValue<string>() === "bet365" && (
              <img
              alt=""
              src="./Images/BooksIcon/bet365.png"
                style={{ width: "15%", height: "15%" }}
              />
            )}

           <Text
          as="p"
            className="text-[14px]  text-neutral-neutral_1100__day_"
          >
            {info.getValue<string>().toUpperCase()}
            </Text>

            
            </div>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Book
          </Text>
        ),
        meta: { width: "8%" },
      }),
      table3ColumnHelper.accessor("differenceLabel", {
        cell: (info) => (
          <button
            className="custom-button font-poppins"
            style={{
              backgroundColor: "white",
              width: "55%",
              color: "GrayText",
              border: "1px solid gray",
            }}
            onClick={() =>
              window.open(info.getValue<string>(), "_blank")
            }
          >
            {Number(info.getValue<string>()).toFixed(2)}
          </button>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3 text-left text-[12px] font-normal text-neutral-neutral_500__day_"
          >
            Odds Diff
          </Text>
        ),
        meta: { width: "8%"  },
      }),
      table3ColumnHelper.accessor("oddsLabel", {
      
        cell: (info) => (

         
         
            <button
              className=" font-poppins font-semibold ml-2"
              style={{
                width: "100%",
                padding: "5px",
                fontSize: "14px",
                color: "#c14bff ", // Asegúrate de que el texto sea visible
                backgroundColor: "white", // Fondo blanco
                border: "1px solid transparent",
                backgroundImage:
                  "linear-gradient(white, white), linear-gradient(220deg, #5c8fff, #c14bff)",
                backgroundOrigin: "border-box",
                backgroundClip: "padding-box, border-box", // Aquí se ajusta el background-clip
                borderRadius: "10px",
              }}
              onClick={() =>
                window.open(info.getValue<string>(), "_blank")
              }
            >
            {info.getValue<string>()}
            </button>
      
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3 ml-5 text-left text-[14px] font-normal text-gradient"
          >
            Lowest Odds
          </Text>
        ),
        meta: { width: "8%" },
      }),
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title> Sports Optimizer Betting - Find the Best Odds</title>
        <meta
          name="description"
          content="Engage in sports positiveEv betting and ensure the best payouts with our comprehensive odds comparison from top bookmakers like MGM, FD, DK, and ESPN."
        />
      </Helmet>
      <div className="flex">
        <Sidebar1 />
        <div className="w-full bg-neutral-neutral_100__day_">
          <div>
            <div className="relative h-[90px] md:h-auto">
              <Header />
            </div>

            <div className="ml-4 h-px bg-neutral-neutral_border__day_ md:ml-0" />

            <div className="mr-7 flex items-start gap-[30px] md:mr-0">
              <div className="flex-1">
                <div className="flex flex-1 mr-10  py-2 justify-center md:self-stretch md:px-5">
                  <Input
                    name="Search Bar"
                    placeholder="Enter your keyword..."
                    value={keywordOption}
                    onChange={(kw) => setKeywordOption(kw.target.value)}
                    prefix={
                      <Img
                        src="/Images/Dashboard-img/search.png"
                        alt="Contrast"
                        className="my-1 h-[100%] w-[100%]"
                      />
                    }
                    suffix={
                      keywordOption.length > 0 ? (
                        <CloseCircle onClick={() => setKeywordOption("")} />
                      ) : null
                    }
                    className="flex h-[48px] w-[18%] items-center justify-center gap-3  rounded-lg border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ px-3 text-[10px] text-blue_gray-300 md:w-full  md:px-5"
                  />
                  <AntSelectBox
                    placeholder="League"
                    value={leagueOption}
                    onChange={(opt: React.SetStateAction<options[]>) =>
                      setLeagueOption(opt)
                    }
                    className="ml-3"
                  />

                  <AntSelectBox
                    placeholder="Market"
                    value={marketOption}
                    options={marketOptions}
                    onChange={(opt: options[]) => setMarketOption(opt)}
                    className="ml-3  w-[15%]"
                  />
                  <AntSelectBox
                    placeholder="Odds"
                    // value={marketOption}
                    // options={marketOptions}
                    // onChange={(opt: options[]) => setMarketOption(opt)}
                    className="ml-3  w-[15%]"
                  />
                </div>
                <div className="rounded-[16px] ml-5 border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ p-5">
                  <ReactTable
                    size="xs"
                    bodyProps={{ className: "" }}
                    cellProps={{
                      className: "border-gray-300 border-b-2 border-solid",
                    }}
                    className="mb-2 md:block md:overflow-x-auto md:whitespace-nowrap"
                    columns={table3Columns}
                    data={filteredPositiveEv}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
