import { Heading, RatingBar, Img } from "../../HomeCom";
import React from "react";

interface Props {
  className?: string;
  userImage?: string;
  userRatingText?: React.ReactNode;
  userReviewCount?: React.ReactNode;
}

export default function UserProfile1({
  userImage = "Images/img_group_1261153276.svg",
  userRatingText = "5.0",
  userReviewCount = "26 REVIEWS",
  ...props
}: Props) {
  return (
    <div
      {...props}
      className={`${props.className} flex items-start md:w-full gap-5`}
    >
      {/* Contenedor flex para mover la imagen a la derecha y abajo */}
      <div className="flex flex-1 items-end justify-end">
        <Img
          src={userImage}
          alt="Product Image"
          className="h-[38px]"
        />
      </div>
      <div className="flex flex-1 flex-col items-start self-center">
        <div className="flex items-start gap-2 self-stretch">
          <RatingBar
            value={5}
            isEditable={true}
            size={16}
            starCount={5}
            className="flex gap-2.5"
          />
          <Heading size="headingmd" as="h6" className="self-center !font-semibold">
            {userRatingText}
          </Heading>
        </div>
        <Heading as="p" className="capitalize !text-blue_gray-100_01">
          {userReviewCount}
        </Heading>
      </div>
    </div>
  );
}
