/* eslint-disable jsx-a11y/heading-has-content */
import { Button, Img, Text } from "./..";
import React from "react";
import { MenuItem, Menu, Sidebar } from "react-pro-sidebar";
import { useNavigate, useLocation } from "react-router-dom";
import { Activity, Shop, ChartSuccess, Profile2User, RefreshSquare, BitcoinCard } from 'iconsax-react';

interface Props { 
  className?: string;
}

export default function Sidebar1({ ...props }: Props) {
  const [collapsed, setCollapsed] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const collapseSidebar = () => {
    setCollapsed(!collapsed);
  };

  const handleNavigation = () => {
    navigate('/PaidOptimizer');
  };

  const handleNavigation1 = () => {
    navigate('/PaidDashboard');
  };

  const handleNavigation2 = () => {
    navigate('/ArbitragePage');
  };

  const handleNavigation3 = () => {
    navigate('/PositiveEVPage');
  };


  return (
    <div className="relative overflow-visible">
      <Sidebar
        {...props}
        width="258px"
        collapsedWidth="110px"
        backgroundColor="#ffffff"
        collapsed={collapsed}
        className={`${props.className} flex flex-col h-screen pt-7 gap-[34px] top-0 px-5 sm:pt-5 sm:px-5 sticky overflow-auto transition-all duration-300 ease-in-out`}
      >
        <div className="relative flex justify-center mb-5">
          {collapsed ? (
            <Img src="/Images/Dashboard-img/LogoArb.png" alt="Logo Colapsado" className="h-15" onClick={handleNavigation1} />
          ) : (
            <Img src="/Images/LogoDark.png" alt="Logo Expandido" className="h-15" onClick={handleNavigation1} />
          )}
          <div className="flex items-center gap-5 ml-auto">
            <h2 className={`text-xl ${collapsed ? 'hidden' : 'block'}`}></h2>
          </div>
        </div>
        <Menu
          menuItemStyles={{
            button: {
              padding: "16px",
              gap: "1px",
              color: "#7d7d8f",
              fontWeight: 500,
              fontSize: "14px",
              marginBottom: "10px",
              borderRadius: "12px",
              '&:hover, &-ps-active': { color: "#ffffff", background: "linear-gradient(220.38deg, #2e5bbe, #78319d)" },
            },
          }}
          className="w-full self-stretch"
        >
          <MenuItem
            onClick={handleNavigation1}
            icon={<Activity />}
            style={location.pathname === '/PaidDashboard' ? {color:'white'} : {color:'gray'}}
            className={` ${location.pathname === '/PaidDashboard' ? 'bg-gradient-to-r from-[#2e5bbe] to-[#78319d] text-white rounded-[12px]' : 'text-[#7d7d8f]'}`}
          >
            {!collapsed && "Dashboard"}
          </MenuItem>
          <MenuItem
            onClick={handleNavigation}
            icon={<Shop />}
            style={location.pathname === '/PaidOptimizer' ? {color:'white'} : {color:'gray'}}
            className={` ${location.pathname === '/PaidOptimizer' ? 'bg-gradient-to-r from-[#2e5bbe] to-[#78319d] text-white rounded-[12px]' : 'text-[#7d7d8f]'}`}
          >
            {!collapsed && "Optimizer"}
          </MenuItem>
          <MenuItem
            onClick={handleNavigation2}
            icon={<ChartSuccess />}
            style={location.pathname === '/ArbitragePage' ? {color:'white'} : {color:'gray'}}
            className={` ${location.pathname === '/ArbitragePage' ? 'bg-gradient-to-r from-[#2e5bbe] to-[#78319d] text-white rounded-[12px]' : 'text-[#7d7d8f]'}`}
          >
            {!collapsed && "Arbitrage"}
          </MenuItem>
          <MenuItem icon={<BitcoinCard />}>
            {!collapsed && "Models"}
          </MenuItem>
          <MenuItem icon={<RefreshSquare />}
             onClick={handleNavigation3}
             style={location.pathname === '/PositiveEVPage' ? {color:'white'} : {color:'gray'}}
             className={` ${location.pathname === '/PositiveEVPage' ? 'bg-gradient-to-r from-[#2e5bbe] to-[#78319d] text-white rounded-[12px]' : 'text-[#7d7d8f]'}`}>
            {!collapsed && "Tools Page (EV)"}
          </MenuItem>
          <MenuItem icon={<Profile2User />}>
            {!collapsed && "Referrals"}
          </MenuItem>
        </Menu>
        {!collapsed && <div className="h-px self-stretch bg-neutral-neutral_border__day_" />}
        {!collapsed ? (
          <div className="w-[100%] flex-col py-10">
            <div className="flex flex-col items-end justify-center self-stretch rounded-[12px] bg-neutral-neutral_border__day_ px-3 py-5">
              <div className="flex w-[88%] flex-col gap-1">
                <Img src="/Images/Dashboard-img/Paid.png" alt="Promo Image" className="h-[118px] w-[74%] object-contain" />
                <div className="relative h-[116px]">
                  <div className="absolute bottom-0 left-0 right-0 m-auto flex flex-1 flex-col items-start gap-5">
                    <div className="relative h-[48px] content-center self-stretch">
                      <Text
                        size="description"
                        as="p"
                        className="flex-1 text-center text-[12px] font-normal leading-4 text-neutral-neutral_1100__day_"
                      >
                        <span>
                          Unlock more information now <br />
                          by upgrading to&nbsp;
                        </span>
                        <span className="font-bold">PRO</span>
                      </Text>
                      <Img
                        src="/Images/Dashboard-img/image 1025.png"
                        alt="Feature Image"
                        className="absolute left-[135px] bottom-[3px] right-[22px] m-auto h-[20px] object-cover"
                      />
                    </div>
                    <Button className="ml-8 flex h-[32px] min-w-[122px] items-center justify-center rounded bg-brand-brand_1 px-3.5 text-center text-[12px] font-semibold text-neutral-neutral_bg__day_">
                      Free 7 Day Trial
                    </Button>
                  </div>
                  <Img src="/Images/Dashboard-img/Group 237535.png" alt="Grid Image" className="absolute right-[-1px] top-0 m-auto h-[30px]" />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Sidebar>
      <Button
        onClick={collapseSidebar}
        className="absolute flex h-[30px] w-[30px] items-center justify-center rounded-[16px] border border-solid border-neutral-neutral_border___day_ bg-neutral-neutral_bg__day shadow-sm"
        style={{ top: '50px',  left: collapsed ? 'calc(94px)' : 'calc(220px + 20px)', transform: 'translateY(-50%)', zIndex: 1000, backgroundColor:'white' }} // Ajusta la posición del botón fuera del sidebar
      >
        <Img src={collapsed ? "/Images/Dashboard-img/arrow-right.png" : "/Images/Dashboard-img/arrow-left.png"} />
      </Button>
    </div>
  );
}
