import { Heading } from "../../../components/Dashboard";
 import ArbitrageRow from "../../../components/Dashboard/ArbitrageRow"; 
import { Suspense } from "react";
import { Tweet } from 'react-tweet'

const arbitrageOpportunitiesGrid = [
{
arbitrageText: "Arbitrage", 
priceText: (
<>
    Bronny James o2.5 -110 Fanduel
<br />
Bronny James u 3.5 +150 Bet365
<br />
<br />
Expected profit: $43
</>
),
},
{
arbitrageText: "Discrepancy Play",
priceText: (
<>
Bronny James o2.5 -110 Fanduel
<br />
Bronny James u 3.5 +150 Bet365
<br />
<br />
Expected profit: $43
</>
),
},
{
arbitrageText: "Arbitrage", 
priceText: (
<>
    Bronny James o2.5 -110 Fanduel
<br />
Bronny James u 3.5 +150 Bet365
<br />
<br />
Expected profit: $43
</>
),
},
{
    arbitrageText: "Arbitrage", 
priceText: (
<>
    Bronny James o2.5 -110 Fanduel
<br />
Bronny James u 3.5 +150 Bet365
<br />
<br />
Expected profit: $43
</>
),
},
{
    arbitrageText: "Models Projection", 
priceText: (
<>
    Bronny James o2.5 -110 Fanduel
<br />
Bronny James u 3.5 +150 Bet365
<br />
<br />
Expected profit: $43
</>
),
},
{
    arbitrageText: "Discrepancy Play", 
    priceText: (
    <>
        Bronny James o2.5 -110 Fanduel
    <br />
    Bronny James u 3.5 +150 Bet365
    <br />
    <br />
    Expected profit: $43
    </>
    ),
    },
    {
        arbitrageText: "Arbitrage", 
priceText: (
<>
    Bronny James o2.5 -110 Fanduel
<br />
Bronny James u 3.5 +150 Bet365
<br />
<br />
Expected profit: $43
</>
),
},
{arbitrageText: "Arbitrage", 
    priceText: (
    <>
        Bronny James o2.5 -110 Fanduel
    <br />
    Bronny James u 3.5 +150 Bet365
    <br />
    <br />
    Expected profit: $43
    </>
    ),
    },
    {arbitrageText: "Arbitrage", 
        priceText: (
        <>
            Bronny James o2.5 -110 Fanduel
        <br />
        Bronny James u 3.5 +150 Bet365
        <br />
        <br />
        Expected profit: $43
        </>
        ),
        },
    ];
    export default function PaidDashboardSection() {

      return (
        <>
          {/* Paid dashboard main section */}
          <div className="flex gap-4 md:flex-col">
            <div className="flex-1 md:self-stretch  md:px-5">
              <div className="flex flex-col gap-3.5  rounded-[16px] border-[.5px] border-solid border-neutral-neutral_border___day_ bg-neutral-neutral_bg__day_ py-8">
                <div className="flex flex-col items-start gap-5">
                  <Heading
                    size="subtitle_semi_bold" 
                    as="h2"
                    className="ml-6 text-[16px] font-semibold text-neutral-neutral_1100__day_ md:m-0"
                  >
                    Today's Top Plays
                  </Heading>
                  <div className="h-px w-full self-stretch bg-gray-100" />
                </div>
                <div className="mx-3.5 grid grid-cols-3 justify-center gap-6 md:mx-0 md:grid-cols-2 sm:grid-cols-1">
                <Suspense fallback={<div>Loading feed...</div>}>
                          {arbitrageOpportunitiesGrid.map((d, index) => (
                            <ArbitrageRow {...d} key={"arbitrageGrid" + index} className="bg-teal-300_26" />
                          ))}
                        </Suspense>
                </div>
              </div>
            </div>

              <div className=" relative h-[670px] w-[395px] content-end rounded-[16px] border-[0.5px] border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ md:h-auto">

                    <div className="mx-6  flex items-center  md:mx-0">
                      <Heading
                        size="subtitle_semi_bold" 
                        as="h3"
                        className="text-[16px] font-semibold text-neutral-neutral_1100__day_"
                      >
                        Live Feed
                      </Heading>
                    </div>
                    <div className="flex relative w-[392px] h-[670px]">
                    <Tweet id="1850402272750006515" />
                    </div>
              </div>
            </div>
          
        </>
      );
      }




