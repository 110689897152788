import { Text, Img, Heading } from "./..";
import React from "react";

interface Props {
  className?: string;
  arbitrageText?: React.ReactNode; 
  priceText?: React.ReactNode; // Note: priceText is not used in the component
}

export default function ArbitrageRow({
  arbitrageText = "Arbitrage",
  priceText, // You can use this prop if needed
  ...props
}: Props) {
  return (
    <div
      {...props}
      className={`flex flex-col  w-[70%] gap-1.5 py-1 border-neutral-neutral_border_day_ border-[0.5px] border-solid rounded-[16px] ${props.className}`}
    >
      <div className="mx-2.5  flex items-start justify-between gap-5 self-stretch">
        <Heading
          size="button_label"
          as="h6"
          className="self-center text-[16px] font-bold tracking-[-0.14px] text-accents-green"
        >
          {arbitrageText}
        </Heading>
        <Img src="/Images/Dashboard-img/export.png" alt="Clock Image" className="h-[14px] w-[14px]" />
      </div>
      <Text size="description" as="p" className="mx-2.5 text-[12px] font-normal leading-4 text-black-900_01">
        <span className="text-black-900_01">
          Bronny James 02.5 -110 Fanduel
          <br />
          Bronny James 3.5 +150 Bet365
          <br />
          <br />
        </span>
        <span className="font-bold text-black-900_01">Expected profit:&nbsp;</span> 
        <span className="font-bold text-accents-green">$43</span>
      </Text>
    </div>
  );
}
