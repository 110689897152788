import React from "react";

type ImgProps = React.ImgHTMLAttributes<HTMLImageElement>;

const Img: React.FC<ImgProps> = ({
  className = "",
  src = "defaultNoData.png",
  alt = "testIng",
  ...restProps
}) => {
  return <img className={className} src={src} alt={alt} {...restProps} loading="lazy" />;
};

export { Img };
