import { Heading, Img } from "../../HomeCom";
import React from "react";

interface Props {
  className?: string;
  walletTitle?: React.ReactNode;
  walletDescription?: React.ReactNode;
}

export default function UserProfile({
  walletTitle = "Create Wallet",
  walletDescription = "Secure your digital assets effortlessly. Join now for peace of mind.",
  className = '', // Default to an empty string
  ...props
}: Props) {
  return (
    <div
      {...props}
      className={`${className}  h-[300px] w-[300px] lg:w-[105%] border-white-a700_47 border border-dashed bg-blue_gray-900_01  cursor-pointer relative rounded-[20px] hover:border-white-a700_47 hover:border hover:border-dashed`}
    >
      <Img
        src="Images/HomePag-img/Union.png"
        alt="Union Image"
        className="absolute  bottom-[-18px] left-0 right-0 m-auto h-full w-full "
      />
      <div className="absolute bottom-1s left-0 right-0 top-0 my-auto flex h-max flex-1 flex-col items-center gap-1 rounded-[20px] bg-gray-900_02  sm:p-5">
        <Img
          src="Images/HomePag-img/Preferences.png"
          alt="Preferences Icon"
          className="h-[100px] w-[100px] object-cover"
        />
        <Heading
          size="headingxl"
          as="h4"
          className="!font-manrope capitalize tracking-[-.52px] text-center text-xl sm:text-2xl md:text-3xl lg:text-4xl"
        >
          {walletTitle}
        </Heading>
        <Heading
          as="p"
          className="mb-[18px] self-stretch text-center !font-normal leading-6 !text-indigo-100 text-sm sm:text-base md:text-lg lg:text-xl"
        >
          {walletDescription}
        </Heading>
      </div>
    </div>
  );
}
