import React from "react";

const sizes = {
  heading_h3: "text-[28px] font-bold md:text-[26px] sm:text-[24px]",
  heading_h2: "text-[32px] font-extrabold md:text-[30px] sm:text-[28px]",
  button_label: "tracking-[-0.14px] text-[16px] font-bold", 
  subtitle: "tracking-[-0.14px] text-[16px] font-medium", 
  subtitle_semi_bold: "text-[16px] font-semibold", 
  text_bold: "text-[14px] font-semibold", 
  headingxs: "text-[11px] font-bold",
};

export type HeadingProps = Partial<{
  className?: string;
  as?: React.ElementType;
  size?: keyof typeof sizes;
}> & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const Heading: React.FC<React.PropsWithChildren<HeadingProps>> = ({
  children,
  className = "",
  size = "subtitle",
  as: Component = "h6", // Default to "h6" if "as" prop is not provided
  ...restProps
}) => {
  return (
    <Component 
      className={`font-poppins ${className} ${sizes[size]}`} 
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Heading };
