import { Text, Heading, Img, Button } from "./.."; 
import React from "react";

interface Props {
  className?: string;
  arbitrageDescription?: React.ReactNode;
  activeArbsText?: React.ReactNode;
  priceText?: React.ReactNode;
  averageArbsText?: React.ReactNode;
}

export default function ArbitrageDashboard({
  arbitrageDescription = "Arbitrage",
  activeArbsText = "5 Active Arbs",
  priceText = "$156",
  averageArbsText = "Average arbs per day: 239",
  ...props
}: Props) {
  return (
    <div
      {...props}
      className={`${props.className} flex items-end w-[24%] md:w-full p-4 md:px-5 border-neutral-neutral_border_day_ border-[1.5px] border-solid bg-neutral-neutral_bg__day_ rounded-[16px]`}
    >
      <div className=" flex w-full flex-col gap-4">
        <div className="flex items-center justify-between gap-5">
          <Text size="description" as="p" className="text-[14px] font-black text-neutral-neutral_400_day_">
            {arbitrageDescription}
          </Text>
          <Img 
            src="/Images/Dashboard-img/Toggle.png" 
            alt="Toggle Image"
            className="h-[12px]" 
          />
        </div>
        <div className="flex items-start justify-center gap-2">
          <Button className="flex  items-center justify-center rounded-lg bg-accents-green px-2" aria-label="Thumbs Up">
          <Img src="Images/Dashboard-img/icons.png" alt="Thumbs Up Icon" className="  h-[32px] w-[25px]" />
          </Button>
          <div className="flex flex-1 flex-col items-end gap-3.5 self-end"> 
            <div className="mr-1.5 flex justify-between gap-5 self-stretch">
              <Heading
                size="button_label" 
                as="h6"
                style={{ color: 'black' }} 
                className="text-[16px] font-bold tracking-[-.14px] "
              >
                {activeArbsText}
              </Heading>
              <div className="flex items-center gap-1.5">
                <Img 
                  src="/Images/Dashboard-img/export.png" 
                  alt="External Link Icon"
                  className="h-[12px] w-[12px]" 
                />
                <Heading as="p" className="text-[16px] font-medium tracking-[-0.14px] text-accents-green">
                  {priceText}
                </Heading>
              </div>
            </div>
            <Text 
              size="textxs"
              as="p"
              className="text-[11px] font-normal text-neutral-neutral_500__day_"
            >
              {averageArbsText}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}
