import { Heading, Img, Input } from "./..";
import React from "react";
import { CloseCircle, Messages1, ExportSquare,Notification,ArrowDown2 } from 'iconsax-react';

interface Props {
  className?: string;
}

export default function Header({ ...props }: Props) {
  const [searchBarValue, setSearchBarValue] = React.useState("");

  return (
    <header {...props} className={`${props.className} flex items-end mx-auto bg-neutral-neutral_bg__day_ flex-1`}>
      <div className="mt-[18px] flex w-full flex-col items-start  ml-10 py-2 " >
        <div className="mx-auto flex w-full max-w-[1830px] items-center justify-between gap-5 md:flex-col md:px-5">
          <div className="flex w-[86%] justify-between gap-5 md:w-full sm:flex-col">
            <Input
              name="searchField"
              placeholder="Search"
              value={searchBarValue}
              onChange={(e) => setSearchBarValue(e.target.value)}
              prefix={
                <Img src="/Images/Dashboard-img/search.png" alt="Contrast" className="h-[100%] w-[100%]" />
              }
              suffix={
                searchBarValue.length > 0 ? (
                  <CloseCircle

                    onClick={() => setSearchBarValue("")}

                  />
                ) : null
              }
              className="flex h-[48px] w-[38%] items-center justify-center gap-2 rounded-[12px] bg-neutral-neutral_100__day_ px-[18px] text-[14px] font-semibold text-neutral-neutral_300__day_ sm:w-full"
            />
                            <div className="flex flex-wrap items-center  ">
                            <ExportSquare className="w-[10%] h-4" />
                            <Heading
                              size="text_bold"
                              as="p"
                              className="ml-2.5 text-[14px] font-semibold text-neutral-neutral_500__day_ "
                            >
                              Browse
                            </Heading>
                            <ArrowDown2 className="h-3 ml-3"
                            />
                          </div>

            <div className="flex gap-8  py-2" >
              <Messages1/>
              <Notification/>
              </div>
            <a href="./#">
              <Img
                src="/Images/Dashboard-img/topbar.png"
                alt="Profile Link"
                className="h-[40px] w-[40px] rounded-[24px] object-cover sm:w-full"
              />
            </a>

          </div>
        </div>
     </div>
    </header>
  );
}
