import { Heading } from "../../HomeCom";
import React from "react";

interface Props {
  className?: string;
  clientCount?: React.ReactNode;
  clientDescription?: React.ReactNode;
}

const ClientStatistics: React.FC<Props> = ({
  clientCount = "250k+",
  clientDescription = "Happy Clients",
  className = "",
  ...props
}) => {
  return (
    <div
      {...props}
      className={`flex flex-col items-center w-[20%] sm:w-full md:w-1/2 lg:w-1/4 p-6 sm:p-10 md:p-10 lg:p-20 xl:p-8 my-10 bg-blue_gray-900_01 bg-repeat-round rounded-2xl border border-blue_gray-700  ${className}`}
    >
      
      <Heading
        size="heading5xl"
        as="h1"
        className="text-xl sm:text-lg md:text-xl lg:text-2xl xl:text-3xl !font-manrope capitalize tracking-[-1.80px] mb-2"
      >
        {clientCount}
      </Heading>

      <Heading
        size="textmd"
        as="p"
        className="text-sm  sm:text-xs md:text-sm lg:text-base xl:text-lg mb-4 !text-indigo-100"
      >
        {clientDescription}
      </Heading>
      
    </div>
  );
}

export default ClientStatistics;
