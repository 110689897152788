import { createRoot } from "react-dom/client";
import "./styles/tailwind.css"
import "./styles/index.css"
import "./styles/font.css"
import App from "./App";

// Obtener el elemento con ID "root"
const container = document.getElementById("root");

if (container === null) {
  throw new Error('Root element not found');
}

// Crear la raíz del React y renderizar la aplicación
const root = createRoot(container);
root.render(<App />);
