import React from "react";
import type { ColumnDef } from "@tanstack/react-table";
import { flexRender, getCoreRowModel, useReactTable, getSortedRowModel } from "@tanstack/react-table";

type ReactTableProps<D extends unknown> = {
  data: D[];
  columns: ColumnDef<D, string>[];
  headerProps?: React.HTMLProps<HTMLTableSectionElement>;
  headerCellProps?: React.HTMLProps<HTMLTableHeaderCellElement>;
  bodyProps?: React.HTMLProps<HTMLTableSectionElement>;
  rowDataProps?: React.HTMLProps<HTMLTableRowElement>; 
  cellProps?: React.HTMLProps<HTMLTableCellElement>;
  size?: keyof typeof sizes;
  className?: string;
  [x: string]: any;
};

const sizes = {
  xs: "py-[50px]",
} as const;

const ReactTable = <D extends unknown>({
  columns,
  data = [],
  headerProps = {},
  headerCellProps = {},
  bodyProps = {},
  className = "",
  rowDataProps = {},
  cellProps = {},
  size,
  ...restConfig
}: ReactTableProps<D>) => {
  const tableConfig = {
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    ...restConfig,
  };

  const table = useReactTable(tableConfig);

  // Render the UI for your table 
  return (
    <table className={className}>
      <thead {...headerProps}>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                {...header.column.columnDef.meta}
                {...headerCellProps}
              >
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...bodyProps}>
        {table.getRowModel().rows.map((row) => (
          <tr {...rowDataProps} key={row.id} className={rowDataProps?.className}>
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                className={`${size ? sizes[size] : ""} ${cellProps?.className}`}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export { ReactTable };
