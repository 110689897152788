import React from "react";

type ImgProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
  className?: string;
  src?: string; 
  alt?: string;
};

const Img: React.FC<ImgProps> = ({
  className = "",
  src = "defaultNoData.png",
  alt = "Image",
  ...restProps
}) => {
  return (
    <img 
      className={className} 
      src={src} 
      alt={alt} 
      loading="lazy" 
      {...restProps} 
    />
  );
};

export { Img };
