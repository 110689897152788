export const GRAPHQL_API_DEV_PROD = "https://prod.updownleftright.io/";
export const GRAPHQL_API_DEV = "https://dev.updownleftright.io/";
export const GRAPHQL_API_LOCAL = "http://localhost:4000/";
export const ENVIROMENT = process.env.REACT_APP_API_ENVIROMENT;

export function getGraphQlApiURL(): string {
  switch (ENVIROMENT) {
    case "dev":
      return GRAPHQL_API_DEV;
    case "local":
      return GRAPHQL_API_LOCAL;
    case "prod":
      return GRAPHQL_API_DEV_PROD;
    default:
      return GRAPHQL_API_DEV;
  }
}
